
import {Component, ProvideReactive, InjectReactive} from 'vue-property-decorator'
import {PropertyResource, PropertyMaintenanceSystemsResource} from "@/resources"
import PropertyMaintenanceSystemBreadcrumb from "@/components/properties/PropertyMaintenanceSystemBreadcrumb.vue"
import PropertyAddress from '@/components/properties/PropertyAddress.vue'
import ClientName from '@/components/clients/ClientName.vue'
import BaseResourceProperty from '@/components/base/BaseResourceProperty.vue'
import PropertySearchField from '@/components/properties/PropertySearchField.vue'
import PropertyMaintenanceSystemsNavigator3 from "@/components/properties/PropertyMaintenanceSystemsNavigator3.vue"
import { BasePropertyMaintenanceSystemsComponent } from '@/components/base/BaseMixins'


@Component({components : {PropertyMaintenanceSystemsNavigator3, PropertySearchField, BaseResourceProperty, PropertyAddress, PropertyMaintenanceSystemBreadcrumb, ClientName}})
export default class PropertyMaintenanceSystemsView extends BasePropertyMaintenanceSystemsComponent {
  @InjectReactive() propertyResource!: PropertyResource
  @ProvideReactive() propertyMaintenanceSystem: PropertyMaintenanceSystemsResource = this.resource

  gotoSystem() {
    // TODO check pms
    this.$router.push({ name: 'propertySystem', params: { pmsId: this.propertyMaintenanceSystem.data().id }})
  }
}
