
import { Component, Prop } from "vue-property-decorator"
import { BaseMaintenanceSystemComponent } from "../base/BaseMixins"

@Component({components:{}})
export default class SystemAndPackageMenuSelector extends BaseMaintenanceSystemComponent {
  
  @Prop() readonly activator!: string

  systemTypeIcon(system : any) {
    
    if (system.icon) {
      return system.icon
    }

    switch (system.levelType) {
      case "SYSTEM" : return "mdi-alpha-s-circle"
      case "SUBSYSTEM" : return "mdi-alpha-s-circle"
      case "COMPONENT" : return "mdi-alpha-c-circle"
      default: return "mdi-google-circles-extended"
    }
  }
   
}
