
import {Component, InjectReactive, Prop} from 'vue-property-decorator'
import {ClientResource, MaintenanceSystemsResource, PropertyMaintenanceSystemsResource, PropertyResource} from "@/resources"
import PropertyMaintenanceSystemFormDialog from "@/components/properties/PropertyMaintenanceSystemFormDialog.vue"
import BaseOptionsMenu from '@/components/base/BaseOptionsMenu.vue'
import SystemAndPackageMenuSelector from '@/components/maintenanceSystems/SystemAndPackageMenuSelector.vue'
import { BasePropertyMaintenanceSystemsComponent, RequiredProjections } from '../base/BaseMixins'
import PropertyMaintenanceSystemSelector from './PropertyMaintenanceSystemSelector.vue'

@RequiredProjections("propertyMaintenanceSystemSummary")
@Component({components: {PropertyMaintenanceSystemSelector, BaseOptionsMenu, PropertyMaintenanceSystemFormDialog, SystemAndPackageMenuSelector}})
export default class PropertyMaintenanceSystemsNavigator3 extends BasePropertyMaintenanceSystemsComponent {

  @Prop({required: true}) propertyMaintenanceSystem !: PropertyMaintenanceSystemsResource
  @InjectReactive() propertyResource!: PropertyResource

  drawer : boolean = true
  mini : boolean = false
  active : any = null

  currentSystemIdx : number = 0

  // TODO remove
  showSystems : boolean = true
  miniHideNav : boolean = false

  addDialog : boolean = false
  preppingAdd : any = {}
  addMenu : boolean = false
  addMenuX : number = 0
  addMenuY : number = 0
  selectedMs !: MaintenanceSystemsResource 
  selectedParentPms !: PropertyMaintenanceSystemsResource
  unarchiving : any = {}
  archivedMenu : boolean = false

  get propertyAddress() {
    return this.pdata.address.address
  }

  get propertyCityState() {
    return this.pdata.address.city
  }

  get pdata() {
    return this.ready ? this.propertyResource.data() : {id:0}
  }

  get cdata() {
    return this.ready ? this.pdata.client : {id:0}
  }

  get clientName() {
    return this.cdata ? ClientResource.getClientName(this.cdata) : "No client assigned"
  }

  isChildActive(system : any) {
    return system.children.map((c:any) => c.id).includes(this.active)
  }

  gotoSystem(pmsId : any) {
    if (this.$route.params.pmsId != pmsId) {
      console.log("Changing route " + this.$route.params.pmsId  + " != " + pmsId)
      this.$router.push({ name: 'propertySystem', params: { pmsId: pmsId }})
    }
  }

  /**
   * Returns true if there are any archived systems
   */
  get hasArchived() {
      return this.archivedSystems.length > 0
  }
  
  /**
   * Returns the list of archived systems.  Because archiving cascades, only returns archived systems with no archived parent.
   */
  get archivedSystems() {
    return this.rdata.filter((r:any) => r.isArchived && !this.parentIsArchived(r))
  }

  /**
   * Returns the PMS data for the root maintenance system resource.
   */
  get rootSystem() {
    return this.rdata.filter((r:any) => !r.maintenanceSystemParentId).map((r:any) => ({...r}))[0]
  }

  get items() {
    // create nodes
    let allSystems : any[] = this.resources.map(r => ({
      name : r.data().maintenanceSystemName,
      icon : r.data().icon ? r.data().icon : (r.data().levelType == "SUBSYSTEM" ? 'mdi-alpha-s-circle' : null),
      location : r.data().location,
      levelType : r.data().levelType,
      id : r.data().id,
      maintenanceSystemId : r.data().maintenanceSystemId,
      key : r.uriFull,
      resource : r,
      children : []
    }))

    // build children
    this.resources.forEach(r => {
      let thisNode = allSystems.find(node => r.data().id == node.id)
      let parentNode = allSystems.find(node => r.data().parentSystemId == node.id)
      if (parentNode) parentNode.children.push(thisNode)
    })

    // may be no 'root' in which case we will be selecting from a collection of
    // leaf nodes
    let root = allSystems.find(node => !node.resource.data().parentSystemId)
    return root ? root.children : allSystems
  }


  /**
   * Returns true if the system has a parent and it is archived.
   * @param system 
   */
  parentIsArchived(system : any) {
      if (!system.parentSystemId) return false
      let parent = this.rdata.find((s:any) => s.id == system.parentSystemId)
      return parent ? (parent.isArchived ? true : false) : false
  }

  async showAddMenu(parentSystemId : any, evt : MouseEvent) {
    this.addMenu = false
    this.$set(this.preppingAdd, parentSystemId, true)

    this.selectedParentPms = new PropertyMaintenanceSystemsResource(parentSystemId)
    await this.selectedParentPms.get()
    this.selectedMs = await this.selectedParentPms.maintenanceSystem.getAssociation()

    // from vuetify example without activator
    this.addMenuX = evt.clientX
    this.addMenuY = evt.clientY
    evt.preventDefault()
    this.$nextTick(() => {
      this.$set(this.preppingAdd, parentSystemId, false)
      this.addMenu = true
    })
  }

  showAddDialog(selectedMaintenanceSystem : any) {
    this.selectedMs = selectedMaintenanceSystem
    this.addDialog = true
  }
  
  unarchive(system : any) {
    this.$set(this.unarchiving, system.id, true)
    var pmsResource = this.resources.find(r => r.data().id == system.id)

    // TODO errors
    pmsResource?.mergePatch({isArchived: false}).then(() => {
    })
    .catch(e => console.error(e))
    .finally(() => {
      this.$set(this.unarchiving, system.id, false)
      this.archivedMenu = false
    })
    
  }
  
}
