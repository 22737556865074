
import {Component, Prop} from 'vue-property-decorator'
import {MaintenanceSystemsResource, PropertyMaintenanceSystemsResource, PropertyResource} from "@/resources"
import PropertyMaintenanceSystemFormDialog from "@/components/properties/PropertyMaintenanceSystemFormDialog.vue"
import AsyncComputed from 'vue-async-computed-decorator'
import BaseOptionsMenu from '@/components/base/BaseOptionsMenu.vue'
import BaseYesNoDialog from '@/components/base/BaseYesNoDialog.vue'
import BaseDeleteAssociationResourceItemDialog from '@/components/base/BaseDeleteAssociationResourceItemDialog.vue'
import { BasePropertyMaintenanceSystemsComponent, RequiredProjections } from '../base/BaseMixins'

@RequiredProjections("propertyMaintenanceSystemDetail")
@Component({components: {BaseOptionsMenu, BaseYesNoDialog, BaseDeleteAssociationResourceItemDialog, PropertyMaintenanceSystemFormDialog}})
export default class PropertyMaintenanceSystemBreadcrumb extends BasePropertyMaintenanceSystemsComponent {
  @Prop({ required : true }) readonly propertyResource!: PropertyResource
  @Prop({ required : true }) readonly selectedPropertyMaintenanceSystem!: PropertyMaintenanceSystemsResource

  editDialog : boolean = false
  deleteDialog : boolean = false
  archiveDialog : boolean = false
  archiving : boolean = false
  rootId : any
  parentSystemId : any

  systems : any = []

  get address() {
    return PropertyResource.getPropertyAddress(this.propertyResource.data())
  }

  archiveSystem() {
    this.archiving = true
    this.selectedPropertyMaintenanceSystem.mergePatch({archived : true}).then(() => {
      this.archiveDialog = false
      this.redirect()
    })
    .finally(() => {
      this.archiving = false
    })
  }

  redirect() {
    if (this.parentSystemId != this.rootId) {
      this.$router.push({ name: 'propertySystem', params: { pmsId: this.parentSystemId }})
    } else {
      this.$router.push({ name: 'property', params: { pmsId: this.propertyResource.data().id }})
    }
  }

  findMs(msid : any, subsystems : any) : any {
    for (const subsystem of subsystems) {
      // Test current object
      if (subsystem.id === msid) { return subsystem; }

      // Test children recursively
      const childSystem = subsystem.subsystems ? this.findMs(msid, subsystem.subsystems) : undefined
      if (childSystem) { return childSystem; }
    }
  }

  @AsyncComputed()
  async fetchCrumbs() {

    // ensure our resources have loaded
    if (!this.success) return []

    // get ms tree
    var msTree = (await (new MaintenanceSystemsResource().root).getAssociation()).data()

    // the selected may not be the projection we need
    var pms_projection = new PropertyMaintenanceSystemsResource(this.selectedPropertyMaintenanceSystem.data().id + "?projection=propertyMaintenanceSystemDetail")
    await pms_projection.get()

    var pms : PropertyMaintenanceSystemsResource | undefined = pms_projection
    var mid = pms.data().maintenanceSystemId
    var ms = this.findMs(mid, msTree.subsystems)

    this.systems = []
    while (ms && pms) {
      this.systems.unshift({text: ms.name, exact: true, to: { name: 'propertySystem', params: { pmsId: pms.data().id }}})
      
      // TODO what if there are two parent systems of the same ms, how do we know which one is our parent, may
      // not be an issue if the breadcrumbs don't go too deep and we only allow dup systems below systems
      // e.g. MS -> systems (1) -> subsytems (1+) -> components (1+) ?
      mid = pms.data().maintenanceSystemParentId
      ms = this.findMs(mid, msTree.subsystems)

      // TODO may be multiple and return the wrong system
      pms = this.resources.find((r) => r.data().maintenanceSystemId == mid)
    }

    // push property
    this.systems.unshift({text: "Maintenance system", exact: true})

  }

  @AsyncComputed()
  async maintenanceSystemsAssociation() {
    return this.propertyResource.maintenanceSystems
  }

  @AsyncComputed()
  async maintenanceSystem() {
    return this.selectedPropertyMaintenanceSystem.maintenanceSystem.getAssociation()
  }


}
